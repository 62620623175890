import { defineStore } from 'pinia';

interface Tab {
  [property: string]: any;
}
/**
 * 一些tab栏的控制
 */
const useExamineTabsStore = defineStore('examineTabs', {
  state: () => {
    return {
      tabsValue: <Tab>{}
    };
  },
  //计算属性
  getters: {},
  actions: {
    /**
     * @param key
     * @param value
     */
    setTabs<T>(key: string, value: T) {
      this.tabsValue[key] = value;
    },
    getTabs(key: string) {
      return this.tabsValue[key];
    }
  }
});

export default useExamineTabsStore;
